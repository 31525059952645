/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { Switch, Route, withRouter, Redirect } from 'react-router-dom';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { LD_CLIENT_ID } from 'utils/environment';

import Dashboard from 'containers/Dashboard/Loadable';
import Sessions from 'containers/Sessions/Loadable';
import Booking from 'containers/Booking/Loadable';
import FinancialServices from 'containers/FinancialServices/Loadable';
import LegalServices from 'containers/LegalServices/Loadable';
import ResearchAssistance from 'containers/ResearchAssistance/Loadable';
import HealthAdvisory from 'containers/HealthAdvisory/Loadable';
import CareerAdvice from 'containers/CareerAdvice/Loadable';
import Preferences from 'containers/Preferences/Loadable';
import Support from 'containers/Support/Loadable';
import NotFoundPage from 'containers/NotFoundPage/Loadable';
import Fonts from 'containers/Fonts/Loadable';
import Matching from 'containers/Matching/Loadable';
import UserInformation from 'containers/UserInformation';
import Misc from 'containers/Misc';
import UpdatePopover from 'containers/UpdatePopover';
import AppointmentNote from 'containers/AppointmentNote/Loadable';
import EmailConfirmation from 'containers/EmailConfirmation';
import MyCareProvider from 'containers/MyCareProvider';
import Messaging from 'containers/Messaging';
import TopNavigationBar from 'containers/TopNavigationBar';
import InviteDependants from 'containers/Preferences/InviteDependants';
import PreSessionAssessment from 'containers/PreSessionAssessment/Loadable';
import PostSessionAssessment from 'containers/PostSessionAssessment/Loadable';
import ProfilePreview from 'containers/ProfilePreview/Loadable';
import PartnerAcceptance from 'containers/PartnerAcceptance/Loadable';
import { getToken } from 'utils/auth';
import AdditionalServices from 'containers/AdditionalServices';
import UpdatedPrivacyConsent from 'containers/UpdatedPrivacyConsents';
import UpdatedTermsConditions from 'containers/UpdatedTermsConditions';
import EmergencyReliefFund from 'containers/EmergencyReliefFund';
import PartnerRegistration from 'containers/PartnerRegistration';
import SignUp from 'containers/SignUp';
import SignIn from 'containers/SignIn';
import WMHSignUp from 'containers/WMHSignUp';
import PhoneVerificationDialog from 'containers/PhoneVerificationDialog';
import DigitalHealthEcosystem from 'containers/DigitalHealthEcosystem/Loadable';
import TimezoneUpdate from 'components/TimezoneUpdate';
import TwoFactorAuthentication from './TwoFactorAuthentication';
import ExternalTokenRedirect from './ExternalTokenRedirect';
import IdleManager from './IdleManager';
import NotificationManager from './NotificationManager';
import Background from './Background';
import GlobalStyle, { tabletSize, phoneSize } from '../../global-styles';
import ConfirmSignUp from '../../components/ConfirmSignUp';
import { injectMetaScript } from './meta-track-script';
import StaticPage from 'containers/StaticPage/Index';
import MigrationModal from 'containers/MigrationModal';

const AppWrapper = styled.div`
  display: flex;
  min-height: 100%;
  flex-direction: column;
`;

const LayoutWrapper = styled.div`
  padding-top: 40px;
  padding-left: 7.5%;
  padding-right: 7.5%;
  padding-bottom: 160px;
  position: relative;
  z-index: 1;

  @media (max-width: ${tabletSize}) {
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 200px;
  }
  @media (max-width: ${phoneSize}) {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

const MinHeight = styled.div`
  min-height: 100vh;
`;

const SkipToMain = styled.a`
  position: absolute;
  top: 0;
  left: -500px;
  &:focus {
    position: relative;
    left: 0;
  }
`;

/* eslint-disable react/prefer-stateless-function */
class App extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { tokenLoaded: false };
    this.tokenLoadInterval = null;
  }

  setTokenLoaded = () => {
    const { tokenLoaded } = this.state;

    if (!tokenLoaded && getToken()) {
      this.setState({ tokenLoaded: true });
      clearInterval(this.tokenLoadInterval);
      this.tokenLoadInterval = null;
    }
  };

  render() {
    const { tokenLoaded } = this.state;
    const { location } = this.props;

    if (location.pathname.match(/^\/redirect/)) {
      return (
        <AppWrapper>
          <ExternalTokenRedirect />
        </AppWrapper>
      );
    }

    // accept partner invitation
    if (location.pathname.match(/^\/partner_acceptance/i)) {
      return (
        <AppWrapper>
          <LayoutWrapper>
            <MinHeight>
              <Background />
              <PartnerAcceptance location={location} />
            </MinHeight>
          </LayoutWrapper>
          <GlobalStyle />
        </AppWrapper>
      );
    }

    // DHE langing page
    if (location.pathname.match(/^\/digital-health-ecosystem/)) {
      return (
        <AppWrapper>
          <LayoutWrapper>
            <MinHeight>
              <Background />
              <DigitalHealthEcosystem />
            </MinHeight>
          </LayoutWrapper>
          <GlobalStyle />
        </AppWrapper>
      );
    }

    if (location.pathname.match(/^\/signin/) && !getToken()) {
      injectMetaScript('SignIn');
      injectMetaScript('PageView');
      return (
        <AppWrapper>
          <SignIn />
        </AppWrapper>
      );
    } else {
      injectMetaScript('PageView');
    }

    // Greenshield cares override
    if (location.search.toLowerCase() && !getToken()) {
      const match = location.search.toLowerCase().match(/company=([a-z]+)/i);
      if (
        (match &&
          (match[1] === 'greenshieldcares' ||
            match[1] === 'greenshieldcares5' ||
            match[1] === 'support' ||
            match[1] === 'greenshieldcares2024')) ||
        match[1] === 'greenshieldcares3'
      ) {
        return (
          <AppWrapper>
            <WMHSignUp />
          </AppWrapper>
        );
      }
    }

    // Greenshield cares relief override
    if (location.search.toLowerCase() && !getToken()) {
      const match = location.search.toLowerCase().match(/company=([a-z]+)/i);
      if (match && match[1] === 'relief') {
        return (
          <AppWrapper>
            <WMHSignUp />
          </AppWrapper>
        );
      }
    }

    if (location.pathname.match(/^\/signup/) && !getToken()) {
      return (
        <AppWrapper>
          <SignUp />
        </AppWrapper>
      );
    }

    // counsellor profile preview
    if (location.pathname.match(/^\/profile_preview/i)) {
      return (
        <AppWrapper>
          <LayoutWrapper>
            <MinHeight>
              <Background />
              <ProfilePreview location={location} />
            </MinHeight>
          </LayoutWrapper>
          <GlobalStyle />
        </AppWrapper>
      );
    }

    // start token load loop for secured pages
    if (!this.tokenLoadInterval) {
      this.tokenLoadInterval = setInterval(this.setTokenLoaded, 50);
    }

    return (
      <AppWrapper>
        <Helmet
          titleTemplate="%s - Inkblot Therapy"
          defaultTitle="Inkblot Therapy"
        >
          <meta name="description" content="Inkblot Therapy" />
        </Helmet>
        <IdleManager />
        <NotificationManager tokenLoaded={tokenLoaded} />
        {/* Global Containers Start */}
        <UserInformation tokenLoaded={tokenLoaded} />
        <Misc tokenLoaded={tokenLoaded} />
        {/* Global Containers End */}
        <SkipToMain href="#main-content">skip to main content</SkipToMain>
        <TopNavigationBar tokenLoaded={tokenLoaded} />
        <TimezoneUpdate />
        <LayoutWrapper role="main" id="main-content">
          {!location.pathname.includes('404') && <Background />}
          <UpdatePopover tokenLoaded={tokenLoaded} />
          <UpdatedPrivacyConsent tokenLoaded={tokenLoaded} />
          <UpdatedTermsConditions tokenLoaded={tokenLoaded} />
          <PhoneVerificationDialog />
          <MigrationModal />
          <MinHeight>
            <Switch>
              <Route exact path="/" component={Dashboard} />
              <Route exact path="/signup" component={Dashboard} />
              <Route exact path="/signin" component={Dashboard} />
              <Route
                exact
                path="/partner_registration"
                component={PartnerRegistration}
              />
              <Route exact path="/fonts" component={Fonts} />
              <Route exact path="/preferences" component={Preferences} />
              <Route
                exact
                path="/invite_dependants"
                component={InviteDependants}
              />
              <Route exact path="/support" component={Support} />
              <Route path="/sessions/booking" component={Booking} />
              <Route
                exact
                path="/services/financial"
                component={FinancialServices}
              />
              <Route exact path="/services/legal" component={LegalServices} />
              <Route
                exact
                path="/services/research"
                component={ResearchAssistance}
              />
              <Route exact path="/services/career" component={CareerAdvice} />
              <Route exact path="/services/health" component={HealthAdvisory} />
              <Route
                exact
                path="/services/emergencyReliefFund"
                component={EmergencyReliefFund}
              />
              <Route
                exact
                path="/sessions/:appointment_id/pre_session_assessments"
                component={PreSessionAssessment}
              />
              <Route
                exact
                path="/sessions/:appointment_id/post_session_assessments"
                component={PostSessionAssessment}
              />
              <Route exact path="/sessions" component={Sessions} />
              <Route
                exact
                path="/sessions/unsuccessful"
                component={StaticPage}
              />
              <Route path="/match" component={Matching} />
              <Route exact path="/confirmation" component={ConfirmSignUp} />
              <Route exact path="/messaging" component={Messaging} />
              <Route exact path="/services" component={AdditionalServices} />
              <Route
                path="/appointment_notes/:id"
                component={AppointmentNote}
              />
              <Route
                exact
                path="/my_care_provider"
                component={MyCareProvider}
              />
              <Route
                path="/confirmation/:confirmation_token"
                component={EmailConfirmation}
              />
              <Route
                path="/two_factor/:code"
                component={TwoFactorAuthentication}
              />
              <Route path="/404" component={NotFoundPage} />
              <Redirect to="/404" />
            </Switch>
          </MinHeight>
        </LayoutWrapper>
        <GlobalStyle />
        <div
          id="aria-live-container"
          aria-live="assertive"
          style={{ height: '0', overflow: 'hidden' }}
        />
      </AppWrapper>
    );
  }
}

App.propTypes = {
  location: PropTypes.object.isRequired,
};

// Launch Darkly SDK configuration reference: https://docs.launchdarkly.com/sdk/client-side/react/react-web#configuring-the-react-sdk
// See React Web SDK v2.X, not v3.X, since we are still using the v2 package
export default withLDProvider({
  clientSideID: LD_CLIENT_ID,
  user: {
    key: 'therapy-v2-anonymous',
    anonymous: true,
  },
  // default/fallback values for flags if unable to retrieve live values from Launch Darkly
  flags: {
    'gsc-direct-claims': false,
    'insurance-sponsored-minutes': false,
  },
})(withRouter(App));
